<template>
  <hero />
  <about />
  <videoPlay />

  <section>
    <p class="mb-5">
      Around the world, patrons rely on the hospitality industry in many aspects
      of their life. From restaurants and hotels, to shops and amusement parks,
      we experience different forms of hospitality on a daily basis. With
      origins dating back thousands of years, hospitality is an important
      concept on which countless businesses operate. To fully understand why
      hospitality is important, you must first familiarize yourself with the
      concept of hospitality and how it affects the world around us.
    </p>
  </section>
</template>

<script>
import hero from "./hero";
import about from "./about";
import videoPlay from "./video";

export default {
  components: {
    hero,
    about,
    videoPlay,
  },
};
</script>


