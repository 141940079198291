<template>
  <main class="bg-video-wrap mb-4 mt-5">
    <video src="https://res.cloudinary.com/hayche-network/video/upload/v1646928490/website/videos/11_qrz1nv.mp4" loop muted autoplay></video>
    <div class="video-overlay">
      <h1>Exploring opportunities in hospitality</h1>
    </div>
  </main>
</template>


<style scoped>
.bg-video-wrap {
  display: grid;
  position: relative;
}

video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.video-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.7) 50%
  );
  background-size: 3px 3px;
  z-index: 2;
}

.video-overlay h1 {
  font-weight: bold;
  font-size: 80px;
  color: white;
  width: 700px;
  text-align: center;
}

@media (max-width: 768px) {
  .video-overlay h1 {
    font-weight: bold;
    font-size: 30px;
    color: white;
    width: 100%;
    text-align: center;
  }
}

.video-overlay img {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .video-overlay img {
    width: 90%;
    margin: 0 auto;
  }
}
</style>