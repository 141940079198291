<template>
  <div class="about container">
    <div class="two-column-grid">
      <div>
        <img
          class="image"
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928331/website/images/about/1_quwjzv.png"
          alt="hospitality experience"
        />
      </div>
      <div>
        <p class="span">THE HOSPITALITY EXPERIENCE</p>
        <h1 class="sub-heading">We provide complete hospitality experience</h1>
        <p>
          Established in 2012, The Hayche Network has quickly become a leading
          hospitality management and recruitment agency, specializing in the
          training and supply of top industry professionals to the Hospitality
          industry.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* hospitality experience */
.about {
  margin-top: 150px;
}


@media (max-width: 768px) {
  .about > p {
    width: 100%;
  }

  .two-column-grid div {
    display: grid;
    place-items: center;
  }
}

.about .two-column-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 100px;
  row-gap: 100px;
  margin-bottom: 108px;
}

.about .two-column-grid .image {
  width: 80%;
  border-radius: var(--border-radius);
  margin: 0 auto;
  object-fit: cover;
}

.about .two-column-grid .span {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #1a1a1a;
  margin-bottom: 19px;
}

.about .two-column-grid h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 768px) {
  .about .two-column-grid h1 {
    font-size: 25px;
  }
}

.about .two-column-grid p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  line-height: 22px;
}
</style>